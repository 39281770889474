import React from 'react'
import styles from './styles.module.css'

const Tabs =  ({tabs, onChangeTab, mode}) => (
  <div className={styles.container}>
    {Object.keys(tabs).map((key, index) => (
      <a
        key={index}
        onClick={() => onChangeTab(key)}
        className={
          tabs[key]?
          mode === 'bold' ? styles.selectedBold : styles.selected
          : ''
        }
      >
        {key}
      </a>
    )
    )}
  </div>
)
export default Tabs;
