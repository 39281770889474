import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Modal from '../../modal'
import ServiceForm from './serviceForm'
import styles from  './styles.module.css'

const ProviderServices = () => {
  const user = useSelector(state => state.userReducer.login)
  const [modalOpen, setModalOpen] = useState(false)

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  return (
   <React.Fragment>
    <button onClick={openModal}>Agregar Servicio</button>
    <div className={styles.list}>
      {user.response && user.response.provider.services.map((service, index) => (
        <div key={index}>
          <h3>{service.name}</h3>
          <p>{service.description}</p>
        </div>
      ))}
    </div>
    <Modal visible={modalOpen} onClose={closeModal}>
      <div className={styles.serviceForm}>
        <ServiceForm onCancel={closeModal} />
      </div>
    </Modal>
   </React.Fragment>
 )
}
export default ProviderServices
