import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styles from './styles.module.css'
import { update } from '../../../reducers/actions/user.js'

const ContactInformation = ({fieldToUpdate}) => {
  const user = useSelector(state => state.userReducer.login.response)
  const dispatch = useDispatch()
  const [form, setForm] = useState({phone: '', email: '', web: ''})
  
  const bindForm = (event) => setForm({...form, [event.target.name]: event.target.value})
  const updateUser = () => {
    const payload = { 
      [fieldToUpdate]: {...user[fieldToUpdate], ...form},
      _id: user._id
    }
    update(dispatch)(payload)
  }

  useEffect(() => {
    if(user && user[fieldToUpdate]) {
      setForm({
        phone: user[fieldToUpdate].phone,
        email: user[fieldToUpdate].email, 
        web: user[fieldToUpdate].web,
      })
    }
  }, [user])

 return (
    <div className={styles.userInformation}>
      <h3>Información de contacto</h3>
      <div className={styles.card}>
        <input name='email' value={form.email} onChange={bindForm} placeholder='Email'/>
        <input name='phone' value={form.phone} onChange={bindForm} placeholder='Teléfono'/>
        <input name='web' value={form.web} onChange={bindForm} placeholder='Web'/>
        <button onClick={updateUser}>Actualizar</button>
      </div>
    </div>
  )
}

export default ContactInformation
