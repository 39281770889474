import React, {useState} from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Tabs from '../../components/tabs'
import PersonalInformation from '../../components/profile/personalInformation'
import PersonalProjects from '../../components/profile/personalProjects'
import { useSelector } from 'react-redux'
import styles from './styles.module.css'

const Profile = ({location}) => {
  const initialTabs = {'Mis Datos': true, 'Mis Proyectos': false}
  if(location.state && location.state.tab) { 
    Object.keys(initialTabs).forEach(key =>  initialTabs[key] = key === location.state.tab)
  }
  const login =  useSelector(state => state.userReducer.login.response)
  const [tabs, setTabs] = useState(initialTabs)
  
  const onChangeTab = tab => {
    const updatedTabs = {}
    Object.keys(tabs).forEach(key =>  updatedTabs[key] = key === tab)
    setTabs(updatedTabs)
  }

  return (
    <Layout>
      <SEO title='Perfil' />
      <div className={styles.profileContainer}>
        <h1>{`Hola ${login && login.name || ''}`}</h1> 
        <Tabs tabs={tabs} onChangeTab={onChangeTab}/>
        {tabs['Mis Datos'] && <PersonalInformation profile='client' />}
        {tabs['Mis Proyectos'] && login && <PersonalProjects />}
      </div>
    </Layout>
  )
}

export default Profile
