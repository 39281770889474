import React from 'react'
import styles from './styles.module.css'

const Modal = ({children, visible, onClose}) => {
  return visible && (
    <React.Fragment>
    <div className={styles.modal} onClick={onClose} >
    </div>
    <div className={styles.content}>  
      <a className={styles.closeBtn} onClick={onClose} >x</a>
      {children}
    </div>
    </React.Fragment>
  )
}
export default Modal
