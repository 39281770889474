import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { update } from '../../../../reducers/actions/user.js'
import styles from './styles.module.css'

const ServiceForm = ({onCancel}) => {
  const {login, updateUser} = useSelector(state => state.userReducer)
  const [updateReducer,  setUpdateReducer] = useState(updateUser)

  const [form, setForm] = useState({name: '', description: ''})
  const bindForm = event => setForm({...form, [event.target.name]: event.target.value})

  const dispatch = useDispatch()
  const addService = () => {
    update(dispatch)({
      _id: login.response._id,
      provider: {
        ...login.response.provider,
        services: [
          ...login.response.provider.services,
          form
        ]
      }
    })
  }

  useEffect(() => {
    console.log(updateReducer)
    console.log(updateUser)
    if(updateReducer.loading && !update.loading) {
      onCancel()
    }

    if(JSON.stringify(updateReducer)!== JSON.stringify(updateUser)){
      setUpdateReducer(updateUser)
    }
  }, updateUser)

  return (
    <div className={styles.container}>
      <h2>Agrega tu servicio!</h2>
      <label>Nombre del servicio</label>
      <input name='name' value={form.name} onChange={bindForm} />
      <label>Descripción</label>
      <textarea name='description' value={form.description} onChange={bindForm} row={5}/>
      <div className={styles.actions}>
        <button onClick={onCancel}>Cancelar</button>
        <button onClick={addService}>Agregar</button>
      </div>
    </div>
  )
}
export default ServiceForm
